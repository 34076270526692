import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';

const routes: Routes = [
  {
    path: 'personal',
    loadChildren: () => import('./route-modules/personal/route-personal.routing.module').then(m => m.RoutePersonalRoutingModule),
  },
  {
    path: 'terms',
    loadChildren: () => import('./route-modules/terms/route-terms.routing.module').then(m => m.RouteTermsRoutingModule),
  },
  {
    path: 'refund-policy',
    loadChildren: () => import('./route-modules/refund/route-refund.routing.module').then(m => m.RouteRefundRoutingModule),
  },
  {
    path: 'privacy',
    loadChildren: () => import('./route-modules/privacy/route-privacy.routing.module').then(m => m.RoutePrivacyRoutingModule),
  },
  {
    path: 'discord',
    loadChildren: () => import('./route-modules/discord/route-discord.routing.module').then(m => m.RouteDiscordRoutingModule),
  },
  {
    path: 'subscription-policy',
    loadChildren: () => import('./route-modules/subscription-policy/route-subscription-policy.routing.module').then(m => m.RouteSubscriptionPolicyRoutingModule),
  },
  {
    path: 'help',
    loadChildren: () => import('./route-modules/help/route-help.routing.module').then(m => m.RouteHelpRoutingModule),
  },
  // {
  //   path: 'pay',
  //   loadChildren: () => import('./route-modules/pay/route.pay.routing.module').then(m => m.RoutePayRoutingModule),
  // },
  // {
  //   path: 'pay/:fbclid',
  //   loadChildren: () => import('./route-modules/pay/route.pay.routing.module').then(m => m.RoutePayRoutingModule),
  // },
  {
    path: 'checkout',
    loadChildren: () => import('./route-modules/pay/route.pay.routing.module').then(m => m.RoutePayRoutingModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./route-modules/pay/route.pay.routing.module').then(m => m.RoutePayRoutingModule),
  },
  {
    path: 'worksheets',
    loadChildren: () => import('./route-modules/pay/route.pay.routing.module').then(m => m.RoutePayRoutingModule),
  },
  {
    path: 'download',
    loadChildren: () => import('./route-modules/download/route.download.routing.module').then(m => m.RouteDownloadRoutingModule),
  },
  {
    path: 'toappstorelg',
    loadChildren: () => import('./route-modules/toappstorelg/toappstorelg.routing.module').then(m => m.ToappstorelgRoutingModule),
  },
  {
    path: 'quiz',
    loadChildren: () => import('./route-modules/quiz/route-quiz.routing.module').then(m => m.QuizRoutingModule),
  },
  {
    path: 'pay',
    redirectTo: 'quiz/pay',
  },
  {
    path: '',
    redirectTo: 'quiz',
    pathMatch: 'full'
  },
  {
    path: '*',
    redirectTo: 'quiz',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {}), TranslateModule],
  exports: [RouterModule, TranslatePipe],
  providers: [TranslateModule],
})
export class AppRoutingModule { }
