import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SnackbarComponent } from './snackbar.component';
import { SnackbarService } from './snackbar.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
  ],
  declarations: [
    SnackbarComponent,
  ],
  providers: [
    SnackbarService,
  ],
  exports: [
    SnackbarComponent,
  ],
})
export class SnackbarModule {
}
