import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/common/services/auth.service';
import { StorageService } from 'src/app/common/services/storage.service';
import { SubscriptionService } from 'src/app/common/services/subscription.service';
import { v4 as uuidv4 } from 'uuid';

export interface APIErrorResponse {
  code?: string;
  debugMsg?: string;
}

@Injectable()
export class ApiErrorsInterceptor implements HttpInterceptor {

  constructor(
    private readonly storageService: StorageService,
    private readonly subscriptionService: SubscriptionService,
    private readonly auth: AuthService,
  ) {
  }
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req;
    if (this.auth.isAuthenticated()) {
      request  = req.clone({
        setHeaders: {
          'Access-Control-Allow-Origin': '*',
          'x-intellectokids-id-token': this.auth.getToken()!,
        },
      });
    } else {
      request  = req.clone({
        setHeaders: {
          'Access-Control-Allow-Origin': '*',
        },
      });
    }
    return next
      .handle(request)
      .pipe(
        map(event => event),
        catchError((error: HttpErrorResponse) => {
          const token = this.storageService.getRefreshToken();
          if ((error.status === 401 || error.status === 403) && !!token) {
            return this.subscriptionService
              .refreshToken(token.refreshToken)
              .pipe(
                switchMap(data => next.handle(request.method === 'POST' ? this.injectTokenAndOrderId(request, data.id_token, request.body) : this.injectToken(request, data.id_token))));
          }

          return throwError(() => error);
        }),
      );
  }

  private injectToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        'Access-Control-Allow-Origin': '*',
        'x-intellectokids-id-token': token,
      },
    });
  }

  private injectTokenAndOrderId(request: HttpRequest<any>, token: string, body: any): HttpRequest<any> {
    if (!!body.orderId) {
      body.orderId = uuidv4();
    }
    return request.clone({
      body,
      setHeaders: {
        'Access-Control-Allow-Origin': '*',
        'x-intellectokids-id-token': token,
      },
    });
  }

}
