import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable, of, retry, Subject } from 'rxjs';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import { API_URL_GATEWAY } from 'src/app/api-service.config';
import { ScreensWithPurchase } from 'src/app/common/enums/screen-with-purchase.enum';
import { SubscriptionStatusesEnum } from 'src/app/common/enums/subscription-statuses.enum';
import { getOfferFromAdditionalPayment, getOffersFromPayment } from 'src/app/common/functions/get-offers-from-payment.function';
import { ActiveSubscriptionsJson } from 'src/app/common/json/active-subscriptions.json-interface';
import { AuthResponseJson } from 'src/app/common/json/auth-response.json-interface';
import { ProductJson } from 'src/app/common/json/product.json-interface';
import { ActiveSubscription } from 'src/app/common/models/active-subscription';
import { Product } from 'src/app/common/models/product';
import { ProductModelsFactory } from 'src/app/common/models/product.models.factory';
import { Variant } from 'src/app/common/models/variant';
import { UpdateApplePurchase, UpdateCardHolderVisible, UpdateLifetimeProductsSuccess, UpdatePaypalDisabled, UpdateProducts, UpdateTwinsSupported } from 'src/app/store/actions/product.actions';
import { UpdateSubscriptions } from 'src/app/store/actions/subscription.actions';
import { GetUserInfoAuth, UpdateDeepLinksSuccess } from 'src/app/store/actions/user-info.actions';
import { selectUserInfo } from 'src/app/store/selectors/user-info.selector';
import { IAppState } from 'src/app/store/states/app.state';
import { IUserInfoState } from 'src/app/store/states/user-info.state';
import { assocEnumToArray } from 'src/app/ui/assoc-enum-to-array.function';
import { GeoService } from 'src/app/ui/geo-service/geo.service';
import { environment } from 'src/environments/environment';

import { PRODUCT_TWINS } from './../constants/product-twins';
import { FenixPaymentTypeEnum } from '../enums/fenix-payment-type.enum';
import { FenixAdditionalTypeEnum } from '../enums/fenix-additional-type.enum';
import { DeepLinkJson } from '../json/deeplink.json-interface';
import { DeeplinkModelsFactory } from '../models/deeplink.models.factory';

@Injectable()
export class SubscriptionService {

  private userInfoObservable = this.store.pipe(select(selectUserInfo));

  private userInfo!: IUserInfoState;

  private destroy = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    @Inject(API_URL_GATEWAY) private api: string,
    private productModelsFactory: ProductModelsFactory,
    private store: Store<IAppState>,
    private deeplinkModelsFactory: DeeplinkModelsFactory,
    private geoService: GeoService,
  ) {
    this.subscriptionStore();
  }

  public getFlagAndProducts(variant: Variant | null): Observable<Product[]> {
    return this.httpClient
      .get<{payload: { flag_value: string }}>(`${ this.api }/onboarding/flag/twins_supported`)
      .pipe(
        mergeMap(answer => {
          const twins = answer.payload.flag_value === 'true';
          this.store.dispatch(new UpdateTwinsSupported(twins));
          return this.getProducts(variant, twins);
        }),
        catchError(() => {
          this.store.dispatch(new UpdateTwinsSupported(false));
          return this.getProducts(variant, false);
        }),
      );
  }

  public getProducts(variant: Variant | null, twins: boolean): Observable<Product[]> {
    const ids = variant ? this.deleteCommaInTheEnd(this.getProductIdsByVariant(variant, twins).toString()) : null;
    return this.httpClient
      .get<{ payload: ProductJson[] }>(`${ this.api }/subscriptions/get_available_products`, { params: ids ? { ids } : {} })
      .pipe(
        retry(3),
        map(response => {
          const result = response.payload.map(item => this.productModelsFactory.getProduct(item));
          this.geoService.setGeo(this.getGeoByCountry(response.payload[0].country));
          this.store.dispatch(new UpdateProducts(result));
          return result;
        }));
  }

  public getAllSubscriptions(): Observable<ActiveSubscription[]> {

    return this.httpClient
      .get<ActiveSubscriptionsJson>(`${ this.api }/subscriptions/get_all_subscriptions`)
      .pipe(
        map(response => {
          const result = this.productModelsFactory
            .getAllSubscriptions(response).sort((a, b) => b.expiredDate!.getTime() - a.expiredDate!.getTime())
            .filter(item => !!item.applications.find(app => app.toLocaleLowerCase() !== 'lg' && app.toLocaleLowerCase() !== 'e4k'));
          if (result.length && this.userInfo!.subscriptionWasSwithed) {
            result[0].status = SubscriptionStatusesEnum.ACTIVE;
          }
          // if (result.length >= 1) {
          //   result.forEach(item => {
          //     if (!!item.applications.find(app => app === 'OXFORD')) {
          //       this.store.dispatch(new UpdatePurchaseSubscription());
          //     }
          //     if (!!item.applications.find(app => app.toLocaleLowerCase() === 'e4k')) {
          //       this.store.dispatch(new UpdatePurchaseSubscription());
          //     }
          //     if (!!item.applications.find(app => app.toLocaleLowerCase() === 'acsp')) {
          //       this.store.dispatch(new UpdateSPSubscription());
          //     }
          //   });
          // }
          this.store.dispatch(new UpdateSubscriptions(result));
          return result;
        }));
  }

  public refreshToken(token: string): Observable<any> {

    let refreshToken = '';

    if (!!token) {
      refreshToken = token;
    } else {
      return of('');
    }

    const params = {
      refresh_token: refreshToken,
    };

    return this.httpClient
      .get<AuthResponseJson>(`https://${ environment.env }-gate.intellectokids-services.com/auth/v4/refresh_tokens`, { params })
      .pipe(map(response => {
        this.store.dispatch(new GetUserInfoAuth(response));
        const now = new Date();
        document.cookie = `lastDate=${ now }`;
        return response;
      }));
  }

  public refreshAndGetInfo(token: string): Observable<ActiveSubscription[]> {
    return this.refreshToken(token)
      .pipe(mergeMap(() => {
        return this.getAllSubscriptions().pipe(map(data => data));
      }));
  }

  public getDeepLinks(): Observable<string> {
    return this.httpClient
      .get<{payload: DeepLinkJson[], message: string}>(`${ this.api }/subscriptions/deep_links`)
      .pipe(map(response => {
        const result = this.deeplinkModelsFactory.getDeepLinks(response.payload);
        this.store.dispatch(new UpdateDeepLinksSuccess(result));
        return response.message;
      }));
  }

  public backNotification(orderId: string): Observable<string> {
    return this.httpClient
      .get<{ status: string }>(`${ this.api }/subscriptions/front_check_order`, { params: { order_id: orderId } })
      .pipe(map(response => response.status));
  }

  public getLifetimeProducts(): Observable<ProductJson[]> {
    return this.httpClient
      .get<{ message: string,
        ok: boolean,
        payload: ProductJson[]}>(`${ this.api }/subscriptions/lifetime_products`)
        .pipe(map(response => {
          const result = response.payload.map(item => this.productModelsFactory.getProduct(item));
          this.store.dispatch(new UpdateLifetimeProductsSuccess(result));
          return  response.payload;
        }));
  }

  public resendWorksheets(): Observable<string> {
    return this.httpClient
      .post<{ message: string}>(`${ this.api }/subscriptions/resend_printables`, {})
      .pipe(
        map(response => response.message));
  }

  public getPaypalDisabled(): Observable<string> {
    return this.httpClient
      .get<{payload: { flag_value: string }}>(`${ this.api }/onboarding/flag/paypal_disabled`)
      .pipe(
        map(answer => {
          const disabled = answer.payload.flag_value === 'true';
          this.store.dispatch(new UpdatePaypalDisabled(disabled));
          return answer.payload.flag_value;
        }),
        catchError(() => {
          this.store.dispatch(new UpdatePaypalDisabled(false));
          return '';
        }),
      );
  }

  public getCardHolderVisible(): Observable<string> {
    return this.httpClient
      .get<{payload: { flag_value: string }}>(`${ this.api }/onboarding/flag/card_holder`)
      .pipe(
        map(answer => {
          const value = answer.payload.flag_value === 'true';
          this.store.dispatch(new UpdateCardHolderVisible(value));
          return answer.payload.flag_value;
        }),
        catchError(() => {
          this.store.dispatch(new UpdateCardHolderVisible(false));
          return '';
        }),
      );
  }

  public getApplePurchaseValue(): Observable<string> {
    return this.httpClient
      .get<{payload: { flag_value: string }}>(`${ this.api }/onboarding/flag/apple_purchase`)
      .pipe(
        map(answer => {
          const value = answer.payload.flag_value === 'true';
          this.store.dispatch(new UpdateApplePurchase(value));
          return answer.payload.flag_value;
        }),
        catchError(() => {
          this.store.dispatch(new UpdateApplePurchase(false));
          return '';
        }),
      );
  }

  private subscriptionStore(): void {
    this.userInfoObservable
      .pipe(takeUntil(this.destroy))
      .subscribe(data => this.userInfo = data!);
  }

  private getProductIdsByVariant(variant: Variant, twins: boolean): string[] {
    const result = new Set<string>();
    const paymentScreens = variant.payment_screen.screens;
    if (!paymentScreens) {
      return [];
    }
    const screen: any = paymentScreens.find(item => !!assocEnumToArray(FenixPaymentTypeEnum).find(elem => elem === item.info.type))!;
    const additionalScreen: any = paymentScreens.find(item => !!assocEnumToArray(FenixAdditionalTypeEnum).find(elem => elem === item.info.type))!;
    const purchaseScreens: any = paymentScreens.filter(item => !!assocEnumToArray(ScreensWithPurchase).find(elem => elem === item.info.type));
    const offers = getOffersFromPayment(screen);
    if (!screen || !offers) {
      return [];
    }
    offers.forEach((item: any) => {
      result.add(item.productId);
      if (twins && PRODUCT_TWINS.has(item.productId)) {
        result.add(PRODUCT_TWINS.get(item.productId)!);
      }
    });
    if (!!additionalScreen && additionalScreen.info.parameters.offer) {
      const offers = getOfferFromAdditionalPayment(additionalScreen);
      offers.forEach((item: any) => {
        result.add(item.productId);
        if (twins && PRODUCT_TWINS.has(item.productId)) {
          result.add(PRODUCT_TWINS.get(item.productId)!);
        }
      });
    }

    // if (!!specialScreen && specialScreen.info.parameters.offer) {
    //   specialScreen.info.parameters.offer.forEach((item: any) => result.add(item.productId));
    // }

    // if (!!errorScreen && errorScreen.info.parameters.offer) {
    //   errorScreen.info.parameters.offer.forEach((item: any) => result.add(item.productId));
    // }

    // if (!!crossGradeScreens && crossGradeScreens.length) {
    //   crossGradeScreens.forEach((item: any) => {
    //     result.add(item.info.parameters.specialOfferId);
    //   });
    // }

    if (!!purchaseScreens && purchaseScreens.length) {
      purchaseScreens.forEach((item: any) => {
        result.add(item.info.parameters.purchaseProductId);
        if (item.info.parameters.secondProductId) {
          result.add(item.info.parameters.secondProductId);
        }
      });
    }

    return [...result];
  }

  private deleteCommaInTheEnd(str: string): string {
    if (str[str.length - 1] === ',') {
      return str.substring(0, str.length - 1);
    }
    return str;
  }

  private getGeoByCountry(country: string): string {
    return country;
  }

}
