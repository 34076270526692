export enum FenixLandingTypeEnum {
  GENDER = 'GENDER',
  AGE = 'AGE',
  PICTURE_FIRST = 'PICTURE_FIRST',
  SUBJECTS = 'SUBJECTS',
  SUBJECTS_BIG = 'SUBJECTS_BIG',
  TWO_BUTTONS = 'TWO_BUTTONS',
  PROFILE = 'PROFILE',
  STRATEGY = 'STRATEGY',
  LOADING = 'LOADING',
  LOADING2 = 'LOADING2',
  TITLE_AND_PICTURE = 'TITLE_AND_PICTURE',
  COINS = 'COINS',
  INVESTORS = 'INVESTORS',
}
