
import { createSelector } from '@ngrx/store';

import { IAppState } from '../states/app.state';
import { IUserInfoState } from '../states/user-info.state';

const userInfoState = (state: IAppState) => state.userInfo;

export const selectUserInfo = createSelector(
  userInfoState,
  (state: IUserInfoState) => state ? state : null,
);

export const selectUserGender = createSelector(
  userInfoState,
  (state: IUserInfoState) => state ? state.userGender : null,
);
