import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { Snackbar, SnackbarType } from './snackbar';

@Injectable()
export class SnackbarService {

  private subject = new Subject<Snackbar>();
  private idx = 0;

  constructor() { }

  public getObservable(): Observable<Snackbar> {
    return this.subject.asObservable();
  }

  public info(title: string, message: string, timeout = 3000): void {
    this.subject.next(new Snackbar(this.idx++, SnackbarType.info, title, message, timeout));
  }

  public success(title: string, message = '', timeout = 3000): void {
    this.subject.next(new Snackbar(this.idx++, SnackbarType.success, title, message, timeout));
  }

  public warning(title: string, message = '', timeout = 3000): void {
    this.subject.next(new Snackbar(this.idx++, SnackbarType.warning, title, message, timeout));
  }

  public error(title: string, message: string, timeout = 0): void {
    this.subject.next(new Snackbar(this.idx++, SnackbarType.error, title, message, timeout));
  }

}
