import { assocEnumToArray } from 'src/app/ui/assoc-enum-to-array.function';

import { OfferTypeEnum } from '../constructor-distribution/enums/offer-type.enum';
import { FenixPaymentTypeEnum } from '../enums/fenix-payment-type.enum';
import { FenixAdditionalTypeEnum } from '../enums/fenix-additional-type.enum';

export function getOffersFromPayment(screen: any): any[] {
  return screen.info.type === FenixPaymentTypeEnum.PAYMENT_CONSTRUCTOR ? getOffersFromBlocks(screen.info.blocks) : screen.info.parameters.firstTimeOffers;
}

export function getOfferFromAdditionalPayment(screen: any): any[] {
  return screen.info.type === FenixAdditionalTypeEnum.ADDITIONAL_CONSTRUCTOR ? getOffersFromBlocks(screen.info.blocks) : screen.info.parameters.offer;
}

export function getOfferFromAdditionalScreen(screen: any): any[] {
  return screen.type === FenixAdditionalTypeEnum.ADDITIONAL_CONSTRUCTOR ? getOffersFromBlocks(screen.blocks) : screen.parameters.offer;
}

export function getOffersFromBlocks(blocks: any): any[] {
  return blocks.find((block: any) => !!assocEnumToArray(OfferTypeEnum).find(elem => elem === block.block.type))!.block.parameters.firstTimeOffers;
}
