import { Variant } from 'src/app/common/models/variant';
import { EVariantActions, VariantActions } from '../actions/variant.actions';
import { initialIVariantState, IVariantState } from '../states/variant.state';

export function variantReducers(
  state = initialIVariantState,
  action: VariantActions,
): IVariantState {
  switch (action.type) {
    case EVariantActions.UpdateAndChangeVariant: {
      if (action.payload) {
        // tslint:disable-next-line: no-console
        console.log('abtest: ', action.payload.abtest, ', variant: ', action.payload.name);
        return {
          ...state,
          variant: setScreenIntoParams(action.payload),
        };
      }
    }

    case EVariantActions.UpdateVariant: {
      if (state.variant) {
        return {
          ...state,
          variant: state.variant,
        };
      }

      if (action.payload) {
        // tslint:disable-next-line: no-console
        console.log('abtest: ', action.payload.abtest, ', variant: ', action.payload.name);
        return {
          ...state,
          variant: action.payload,
        };
      }
    }

    default : return state;
  }
}

function setScreenIntoParams(elem: Variant): Variant {
  const variant: Variant = JSON.parse(JSON.stringify(elem));
  const paymentScreens = variant.payment_screen.screens ?
    variant.payment_screen.screens!.map(item => {
      return { order: item.order, info: item.info };
    }) : variant.payment_screen.parameters.prevScreens!;

  variant.payment_screen.parameters.prevScreens = paymentScreens!.map(screen => {
    if (screen.info.blocks && screen.info.blocks.length) {
      screen.info.parameters.blocks = screen.info.blocks.map((item: any) => {
        item.block.parameters = item.block.parameters ? item.block.parameters : {};
        item.block.parameters.vats = variant.parameters.vats ? variant.parameters.vats : {};
        return item;
      });
    }
    if (screen.info.parameters) {
      const vats = variant.parameters.vats ? variant.parameters.vats : {};
      const parameters = { ...screen.info.parameters, vats };
      screen.info.parameters = parameters;
    }
    return screen;
  });

  variant.payment_screen.screens = variant.payment_screen.parameters.prevScreens;

  return variant;
}