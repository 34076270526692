import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { GetUserInfoBrowserID } from 'src/app/store/actions/user-info.actions';
import { IAppState } from 'src/app/store/states/app.state';

@Injectable()
export class StorageService {

  private readonly storageIDKey = '_IK_storage_';

  constructor(
    private store: Store<IAppState>,
    ) {}

  public get isExist(): boolean {
    return !!localStorage.getItem(this.storageIDKey);
  }

  public userIdExist(): boolean {
    return this.isExist;
  }

  public getUserId(): string {
    if (this.isExist) {
      const user: {userId: string} = JSON.parse(localStorage.getItem(this.storageIDKey)!);
      if (user.userId) {
        this.store.dispatch(new GetUserInfoBrowserID(user.userId));
        return user.userId;
      }
    }
    const userId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    const user: any = JSON.parse(localStorage.getItem(this.storageIDKey)!);
    const refreshToken = user ? user.refreshToken : '';
    const email =  user ? user.email : '';
    localStorage.setItem(this.storageIDKey, JSON.stringify({ userId, refreshToken, email }));
    this.store.dispatch(new GetUserInfoBrowserID(userId));
    return userId;
  }

  public getEmail(): string {
    const user: any = JSON.parse(localStorage.getItem(this.storageIDKey)!);
    if (user && user.email) {
      return user.email;
    }
    return ''
  }

  public setRefreshTokenAndEmail(refreshToken: string | null, email: string | null): void {
    let userId = '';
    if (this.isExist) {
      const user: {userId: string} = JSON.parse(localStorage.getItem(this.storageIDKey)!);
      userId = user.userId;
    }
    localStorage.setItem(this.storageIDKey, JSON.stringify({ userId, refreshToken, email }));
  }

  public setVariantName(variant: string): void {
    if (this.isExist) {
      const user: any = JSON.parse(localStorage.getItem(this.storageIDKey)!);
      user.variant = variant;
      localStorage.setItem(this.storageIDKey, JSON.stringify(user));
      return;
    }
    const userId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    const user: any = {};
    const refreshToken = user ? user.refreshToken : '';
    const email =  user ? user.email : '';
    user.variant = variant;
    user.userId = userId;
    user.refreshToken = refreshToken;
    user.email = email;
    localStorage.setItem(this.storageIDKey, JSON.stringify(user));
    this.store.dispatch(new GetUserInfoBrowserID(userId));
  }

  public getRefreshToken(): {refreshToken: string, email: string} | null {
    const token: {refreshToken: string, email: string} = JSON.parse(localStorage.getItem(this.storageIDKey)!);
    return token;
  }

  public saveUserAnswers(answers: any): void {
    localStorage.setItem('answers', JSON.stringify(answers));
  }

  public getUserAnswers(): any {
    return JSON.parse(localStorage.getItem('answers')!);
  }
}
