
<div class="container">
  <div *ngIf="matIcon === 'warning'" class="icon">
    {{ 'upsale_pushError_subject' | translate }}
    <img src="assets/images/oops.jpg" width="20" height="20" alt="Oops">
  </div>
  <div *ngIf="matIcon === 'success'" class="icon">
    Great!
    <img src="assets/images/great.jpg" width="20" height="20" alt="Great!">
  </div>
  <div
    class="message"
  >
    {{ message | translate }}
  </div>
</div>

