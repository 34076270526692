import { Injectable } from '@angular/core';

@Injectable()
export class SubscribeErrorMessagesService {
  public getMessageByCode(code: string): string {
    switch (code) {
      case '3.02': return 'Error_NotEnoughFunds';
      case '2.06': return 'Error_CVVIsWrong';
      case '7.01': return 'Error_CVVIsWrong';
      case '2.08': return 'Error_CardWrongNumber';
      case '2.09': return 'Error_CardExpired';
      case '3.06': return 'Error_DebitDontSupport';
      case '2.14': return 'Error_AlreadySubscribed';
      default: return 'Error_CardBlock';
    }
  }
}
