import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Snackbar, SnackbarType } from './snackbar';
import { SnackbarService } from './snackbar.service';

@Component({
  selector: 'ik-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackbarComponent implements OnInit, OnDestroy {

  public snackbars: Snackbar[] = [];

  private subscription!: Subscription;

  constructor(
    private readonly snackbarService: SnackbarService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) { }

  public ngOnInit(): void {
    this.subscription = this.snackbarService.getObservable()
      .subscribe(snackbar => this.addSnackbar(snackbar));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public close(snackbar: Snackbar): void {
    this.snackbars = this.snackbars.filter(notif => notif.id !== snackbar.id);
    this.changeDetectorRef.markForCheck();
  }

  public className(snackbar: Snackbar): string {

    let style: string;

    switch (snackbar.type) {

      case SnackbarType.success:
        style = 'success';
        break;

      case SnackbarType.warning:
        style = 'warning';
        break;

      case SnackbarType.error:
        style = 'error';
        break;

      default:
        style = 'info';
        break;
    }

    return style;
  }

  private addSnackbar(snackbar: Snackbar): void {
    this.snackbars.push(snackbar);
    if (snackbar.timeout !== 0) {
      setTimeout(() => this.close(snackbar), snackbar.timeout);
    }
    this.changeDetectorRef.markForCheck();
  }
}
