import { AnalyticService } from './analytic.service';
import { AuthService } from './auth.service';
import { FacebookPixelService } from './fbpixel.service';
import { LifeTimeService } from './lifetime.service';
import { PaymaxisService } from './paymaxis.service';
import { QuoraService } from './quora.service';
import { ScreenService } from './screen.service';
import { SolidService } from './solid.service';
import { SputnikService } from './sputnik.service';
import { StorageService } from './storage.service';
import { SubscribeErrorMessagesService } from './subscribe-error-messages.service';
import { SubscriptionService } from './subscription.service';
import { TiktokService } from './tiktok.service';
import { UrlDataService } from './url-data.service';

export const COMMON_SERVICES = [
  StorageService,
  AuthService,
  SubscribeErrorMessagesService,
  FacebookPixelService,
  SubscriptionService,
  UrlDataService,
  ScreenService,
  AnalyticService,
  QuoraService,
  SputnikService,
  SolidService,
  PaymaxisService,
  LifeTimeService,
  TiktokService,
];
