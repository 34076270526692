import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { API_URL_GATEWAY } from 'src/app/api-service.config';
import { ProductJson } from 'src/app/common/json/product.json-interface';

@Injectable({
  providedIn: 'root',
})
export class GeoService {
  private geo = new BehaviorSubject('');

  constructor(
    private httpClient: HttpClient,
    @Inject(API_URL_GATEWAY) private api: string,
  ) {
    
  }

  public getGeo(): Observable<string> {
    if (this.geo.value === '') {
      this.getProducts().subscribe();
    }
    return this.geo.asObservable();
  }

  public setGeo(geo: string): void {
    this.geo.next(geo);
  }

  private getProducts(): Observable<ProductJson[]> {
    const ids = ['f6d73601-a150-4a54-ba2a-db857a166b45'];
    return this.httpClient
      .get<{ payload: ProductJson[] }>(`${ this.api }/subscriptions/get_available_products`, { params: { ids } })
      .pipe(
        map(response => {
          this.setGeo(response.payload[0].country);
          return response.payload;
        }));
  }
}
