import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_URL_GATEWAY } from 'src/app/api-service.config';
import { selectAllData } from 'src/app/store/selectors/app.selector';
import { IAppState } from 'src/app/store/states/app.state';

import { ProductModelsFactory } from '../models/product.models.factory';

@Injectable()
export class SputnikService {

  private allDataObservable = this.store.pipe(select(selectAllData));

  private allData!: IAppState;

  constructor(
    private httpClient: HttpClient,
    @Inject(API_URL_GATEWAY) private api: string,
    private store: Store<IAppState>,
    private translate: TranslateService,
    private productModelsFactory: ProductModelsFactory,
  ) {
    this.subscriptionStore();
  }

  public sendAnswers(email: string, eventTypeKey = 'UserAfterFenix'): Observable<string> {
    const body = this.getBody(email, eventTypeKey);
    return this.httpClient
      .post<string>(`${ this.api }/esp/event`, body)
      .pipe(map(data => {
        return data.toString();
      }));
  }

  public sendAnswersAfterName(): Observable<string> {
    if (!this.allData.userInfo.email) {
      return of('');
    }
    const body = this.getBody(this.allData.userInfo.email, 'UserAfterFenix');
    return this.httpClient
      .post<string>(`${ this.api }/esp/event`, body)
      .pipe(map(data => {
        return data.toString();
      }));
  }

  public espAfterPurchase(): Observable<string> {
    const email = this.allData!.userInfo.email!;
    const body: any = {
      eventTypeKey: 'tax_guide_purchase',
      params: {
        email,
      },
    };
    return this.httpClient
      .post<string>(`${ this.api }/esp/event`, body)
      .pipe(map(data => {
        return data.toString();
      }));
  }

  public espAfterSPPurchase(trial: boolean, email: string, LG_LINK: string, paymentDate = ''): Observable<string> {
    const body: any = {
      eventTypeKey: 'sp_full_sub',
      params: {
        email,
        LG_LINK,
      },
    };
    if (!trial) {
      body.params.new_payment_date = paymentDate;
    }
    return this.httpClient
      .post<string>(`${ this.api }/esp/event`, body)
      .pipe(map(data => {
        return data.toString();
      }));
  }

  public sendWelcomeData(): Observable<string> {
    const json = {
      languageCode: this.translate.currentLang,
    };
    const product = this.allData!.userInfo.product!;
    if (!product) {
      return of('');
    }
    const period = this.productModelsFactory.getPeriodByProduct(product);
    const body = {
      eventTypeKey: 'UserAfterFenix',
      payment_date: moment(new Date()).format('DD-MM-YYYY'),
      purchased_plan: `IntellectoKids(${ period })`,
      plan_paid_until: moment(new Date()).add(product.trialDays ? product.trialDays : product.periodInDays, 'day').format('DD-MM-YYYY'),
      email: this.allData.userInfo.email,
      transaction_id: 'Secret',
      amount: (product.priceCents / 100).toString(),
      currency: product.currency,
      duration: this.productModelsFactory.getPeriodByProduct(product),
      language_update_json: JSON.stringify(json),
      trial_period: product.trialDays.toString(),
    };

    return this.httpClient
      .post<string>(`${ this.api }/subscriptions/welcome_email_data`, body)
      .pipe(map(data => {
        return data.toString();
      }));
  }

  private getBody(email: string, eventTypeKey = 'UserAfterFenix'): any {
    const json = {
      languageCode: this.translate.currentLang,
    };
    const body = {
      eventTypeKey,
      params: {
        email,
        child_name: this.allData!.userInfo.childName,
        child_age: this.allData!.userInfo.userAge,
        interests1: this.allData!.userInfo.interests1.toString().split(',').join(', '),
        interests2: this.allData!.userInfo.interests2.toString().split(',').join(', '),
        languageUpdateJson: JSON.stringify(json),
      },
    };
    return body;
  }

  private subscriptionStore(): void {
    this.allDataObservable
      .subscribe(data => this.allData = data!);
  }
}
