import { AuthChallengeResponseJson } from 'src/app/common/json/auth-challenge-response.json-interface';
import { AuthResponseJson } from 'src/app/common/json/auth-response.json-interface';
import { DeepLink } from 'src/app/common/models/deeplink';
import { Product } from 'src/app/common/models/product';

import { Payment } from './../../common/models/payment';
import { PaymentViewOffer } from './../../common/models/payment-view-offer';

export interface IUserInfoState {
  email: string | null;
  ip: string;
  payment: Payment | null;
  product: Product | null;
  purchases: Product[] | null;
  offerView: PaymentViewOffer | null;
  challenge: AuthChallengeResponseJson | null;
  auth: AuthResponseJson | null;
  browserID: string | null;
  promoter: string | null;
  upgradeSubscription: boolean;
  deepLinks: DeepLink[] | null;
  counter: number;
  childName: string | null;
  userAge: string | null;
  interests1: string[];
  interests2: string[];
  subscriptionWasSwithed: boolean;
  answersMap: Map<string, string>;
  answersKeyMap: Map<string, string | string[]>;
  source: string;
  userGender: string | null;
  taxGuidePurchase: boolean;
}

export const initialUserInfoState: IUserInfoState = {
  email: null,
  ip: '',
  payment: null,
  product: null,
  offerView: null,
  challenge: null,
  auth: null,
  browserID: null,
  promoter: null,
  upgradeSubscription: false,
  deepLinks: null,
  purchases: null,
  counter: 0,
  childName: '',
  subscriptionWasSwithed: false,
  userAge: null,
  interests1: [],
  interests2: [],
  answersMap: new Map(),
  answersKeyMap: new Map(),
  source: '',
  userGender: 'none',
  taxGuidePurchase: false,
};
