<div class="snackbars">
  
	<div *ngFor="let snackbar of snackbars" class="snackbar" [ngClass]="className(snackbar)">
   
		<ng-container *ngTemplateOutlet="snackbarTpl; context:{snackbar:snackbar}">
      
    </ng-container>
	</div>
</div>

<ng-template #snackbarTpl let-snackbar="snackbar">
  <ng-container [ngSwitch]="className(snackbar)">
    <ng-container *ngSwitchCase="'success'">
      <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM16.1624 10.4129C16.5356 10.0058 16.5081 9.37319 16.101 9C15.6939 8.62681 15.0613 8.65431 14.6881 9.06143L11.3405 12.6534L10.0364 11.111C9.68288 10.6867 9.05231 10.6294 8.62804 10.9829C8.20376 11.3365 8.14644 11.967 8.5 12.3913L10.5364 14.8126C10.7212 15.0342 10.9926 15.1653 11.2811 15.1721C11.5695 15.1789 11.8468 15.0608 12.0418 14.8481L16.1624 10.4129Z" fill="#008F5D"/>
      </svg>
    </ng-container>
    <ng-container *ngSwitchCase="'warning'">
      <img class="icon" src="assets/images/oops.jpg" width="20" height="20" alt="Oops">
    </ng-container>
    <ng-container *ngSwitchCase="'error'">
      <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.0659 7.76554C11.0659 7.24947 11.4843 6.83112 12.0003 6.83112C12.5164 6.83112 12.9348 7.24947 12.9348 7.76554V12.5891C12.9348 13.1051 12.5164 13.5235 12.0003 13.5235C11.4843 13.5235 11.0659 13.1051 11.0659 12.5891V7.76554Z" fill="#EA3829"/>
        <path d="M11.0659 16.2345C11.0659 15.7184 11.4843 15.3 12.0003 15.3C12.5164 15.3 12.9348 15.7184 12.9348 16.2345C12.9348 16.7505 12.5164 17.1689 12.0003 17.1689C11.4843 17.1689 11.0659 16.7505 11.0659 16.2345Z" fill="#EA3829"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1.65576C6.28703 1.65576 1.65576 6.28703 1.65576 12C1.65576 17.713 6.28703 22.3442 12 22.3442C17.713 22.3442 22.3442 17.713 22.3442 12C22.3442 6.28703 17.713 1.65576 12 1.65576ZM3.65576 12C3.65576 7.3916 7.3916 3.65576 12 3.65576C16.6084 3.65576 20.3442 7.3916 20.3442 12C20.3442 16.6084 16.6084 20.3442 12 20.3442C7.3916 20.3442 3.65576 16.6084 3.65576 12Z" fill="#EA3829"/>
      </svg>
    </ng-container>
    <ng-container *ngSwitchCase="'info'">
    
    </ng-container>
    <ng-container *ngSwitchDefault>
    
    </ng-container>
  </ng-container>
  <button *ngIf="!snackbar.timeout" class="link" (click)="close(snackbar)">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.19721 11.8033C2.90432 12.0962 2.90432 12.5711 3.19721 12.864C3.4901 13.1569 3.96498 13.1569 4.25787 12.864L8.02357 9.09828L11.7893 12.864C12.0822 13.1569 12.557 13.1569 12.8499 12.864C13.1428 12.5711 13.1428 12.0962 12.8499 11.8033L9.08423 8.03762L12.8499 4.272C13.1427 3.97911 13.1427 3.50423 12.8499 3.21134C12.557 2.91845 12.0821 2.91845 11.7892 3.21134L8.02357 6.97696L4.25794 3.21134C3.96505 2.91845 3.49018 2.91845 3.19728 3.21134C2.90439 3.50423 2.90439 3.97911 3.19728 4.272L6.96291 8.03762L3.19721 11.8033Z" fill="#0D0D15"/>
    </svg>
  </button>
	<div class="title" fxLayout="row" fxLayoutAlign="space-between center">
		<div>{{ snackbar.title | translate }}</div>
	</div>
</ng-template>
