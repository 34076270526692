import { TranslateService } from '@ngx-translate/core';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'ik-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {

  private destroy = new Subject<void>();

  constructor(
    private readonly translate: TranslateService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    translate.setDefaultLang('en');
  }

  public ngOnInit(): void {
    this.checkTranslationReady();
  }

  public ngOnDestroy(): void {
  }

  private checkTranslationReady(): void {
    this.translate.stream('Button_Continue')
      .pipe(takeUntil(this.destroy))
      .subscribe(answer => answer !== 'onboarding_usp_button_textUnderButton' ? this.closeLoader() : '');
  }

  private closeLoader(): void {
    const loader = this.document.getElementById('row');
    if (loader) {
      loader.style.opacity = '0';
      setTimeout(() => loader.style.display = 'none', 500);
    }
  }

}
