import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { ClearPaymentLink } from 'src/app/store/actions/payment-link.actions';
import { UpdateSubscriptions } from 'src/app/store/actions/subscription.actions';
import { GetUserInfoAuth, GetUserInfoChallenge, GetUserInfoEmail } from 'src/app/store/actions/user-info.actions';
import { selectAllData } from 'src/app/store/selectors/app.selector';
import { IAppState } from 'src/app/store/states/app.state';
import { IUserInfoState } from 'src/app/store/states/user-info.state';
import { environment } from 'src/environments/environment';

import { API_URL_GATEWAY } from '../../api-service.config';
import { AuthChallengeResponseJson } from '../json/auth-challenge-response.json-interface';
import { AuthResponseJson } from '../json/auth-response.json-interface';

import { StorageService } from './storage.service';

@Injectable()
export class AuthService {

  private userInfo!: IUserInfoState;

  private destroy = new Subject<void>();

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(API_URL_GATEWAY) private api: string,
    private readonly store: Store<IAppState>,
    private readonly storageService: StorageService,
    private readonly router: Router,
  ) {
    this.subscriptionStore();
  }

  public getToken(): string | null {
    if (this.userInfo && this.userInfo.auth && this.userInfo.auth.id_token) {
      const { id_token } = this.userInfo.auth;

      return id_token;
    }
    return null;
  }

  public isAuthenticated(): boolean {
    return !!this.getToken();
  }

  public signUp(email: string): Observable<string> {
    return this.onlySignUp(email);
  }

  public signIn(email: string): Observable<string> {
    return this.httpClient
      .post<AuthChallengeResponseJson>(`${ this.api }/auth/email_sign_in`, { email })
      .pipe(map(response => {
        this.store.dispatch(new GetUserInfoChallenge(response));
        const { user_name = '' } = response;
        return user_name;
      }));
  }

  public onlySignUp(email: string): Observable<string> {
    return this.httpClient
      .post<AuthResponseJson>(`${ this.api }/auth/admin_sign_up`, { email, child_name: this.userInfo.childName, site_project: 'fenix' })
      .pipe(map(response => {
        response.email = email;
        this.storageService.setRefreshTokenAndEmail(response.refresh_token, email);
        const now = new Date();
        document.cookie = `lastDate=${ now }`;
        this.store.dispatch(new GetUserInfoAuth(response));
        const answers: any = {};
        this.userInfo.answersKeyMap.forEach((value, key) => answers[key] = value);
        this.storageService.saveUserAnswers(answers);
        return 'OK';
      }));
  }

  public emailCheck(answer: string): Observable<string> {
    const {
      email_challenge_session,
      user_name,
    } = this.userInfo.challenge!;
    return this.httpClient
      .post<AuthResponseJson>(`${ this.api }/auth/answer_email_challenge`, {
        answer,
        email_challenge_session,
        user_name,
      })
      .pipe(
        catchError((err: any) => {
          if (err.error.message === 'WrongConfirmationCodeError') {
            this.store.dispatch(new GetUserInfoChallenge({
              email_challenge_session: err.error.email_challenge_session,
              user_name: err.error.user_name,
            }));
          }
          err.from = 'answer_email_challenge';
          return throwError(err);
        }),
        map(response => {
          this.storageService.setRefreshTokenAndEmail(response.refresh_token, this.userInfo.email!);
          this.store.dispatch(new GetUserInfoAuth(response));
          return 'OK';
        }));
  }

  public sendLink(email: string): Observable<string> {
    return this.httpClient
      .post<{ message: string }>(`${ this.api }/auth/link/create`, { email, site_project: 'fenix' })
      .pipe(
        map(response => response.message));
  }

  public authById(id: string): Observable<AuthResponseJson> {
    return this.httpClient
      .post< AuthResponseJson >(`${ this.api }/auth/link`, { id })
      .pipe(
        map(response => {
          this.store.dispatch(new GetUserInfoAuth(response));
          this.storageService.setRefreshTokenAndEmail(response.refresh_token, response.email);
          return response;
        }));
  }

  public signOut(): void {
    this.storageService.setRefreshTokenAndEmail(null, null);
    this.store.dispatch(new UpdateSubscriptions([]));
    this.store.dispatch(new GetUserInfoEmail(null));
    this.store.dispatch(new ClearPaymentLink());
    document.cookie = 'hasSeenOffer=false';
    document.cookie = 'hasSeenAdditionalOffer=false';
    document.cookie = 'hasSeenErrorOffer=false';
    document.cookie = 'I_already_saw_the_second_offer=false';
    const pathname = window.location.pathname.replace('pay', 'login');
    this.router.navigate([`${ pathname }`], { queryParamsHandling: 'merge' });
  }

  public generateLinkToPersonalAccount(): void {
    const url = environment.env === 'prod' ? 'https://intellectokids.com/personal' : 'https://d1z9wy4ywo3tjl.cloudfront.net/personal';
    const email = this.userInfo.email;
    const token = this.userInfo.auth!.refresh_token;
    window.open(`${ url }/?e=${ email }&t=${ token }`);
  }

  private subscriptionStore(): void {
    this.store.pipe(select(selectAllData))
      .pipe(takeUntil(this.destroy))
      .subscribe(data => {
        this.userInfo = data!.userInfo;
      });
  }
}
