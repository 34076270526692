import { FenixEmailTypeEnum } from '../enums/fenix-email-type.enum';
import { FenixInfoTypeEnum } from '../enums/fenix-info-type.enum';
import { FenixLandingTypeEnum } from '../enums/fenix-landing-type.enum';
import { FenixPaymentTypeEnum } from '../enums/fenix-payment-type.enum';
import { Variant } from './../models/variant';

export const DEFAULT_VARIANT: Variant = {
  abtest: 'Default from Front',
  "id": "fd5f8cf6-4977-43db-b76f-8f844e333f29",
  "name": "Default from Front",
  "onboarding": {
    "id": "22719c37-40a5-4f0a-b983-f1e9a60f8dce",
    "name": "funnel2",
    "screens": [
      {
        "order": 1,
        "info": {
          "id": "b9b6a614-9fdb-4512-9cc2-bea1ba41f769",
          type: FenixLandingTypeEnum.GENDER,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "hideGoBackBtn": true,
            "name": "funnel2__001",
            "noCountForStep": true,
            "pictures": [],
            "questions": [],
            "result": [],
            "stepHidden": true,
            "subjects": [],
            "text": "select_your_gender",
            "title": "boost_income_with crypto"
          },
          
          "updated_at": 1720448053970,
          "locked": false
        }
      },
      {
        "order": 2,
        "info": {
          "id": "5c11b4ec-418d-4cb0-bdf8-fbf9a0b1d2ba",
          "type": FenixLandingTypeEnum.AGE,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "name": "funnel2__002",
            "noCountForStep": true,
            "pictures": [],
            "question": "1_minute_quiz",
            "questions": [],
            "result": [],
            "stepHidden": true,
            "subjects": [
              "18–24",
              "25–34",
              "35–44",
              "45+"
            ],
            "text": "select_age",
            "title": "get_personal_recommend"
          },
          
          "updated_at": 1720448057878,
          "locked": false
        }
      },
      {
        "order": 3,
        "info": {
          "id": "cb10dd9e-4fd9-4de0-9565-b540e1bbc01f",
          "type": FenixLandingTypeEnum.PICTURE_FIRST,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "buttonText": "Button_Continue",
            "hideGoBackBtn": true,
            "name": "funnel2__003",
            "noCountForStep": true,
            "picture": "Map",
            "pictures": [],
            "questions": [],
            "result": [],
            "stepHidden": true,
            "subjects": [],
            "text": "we_help_exclore_crypto",
            "title": "300M_people"
          },
          
          "updated_at": 1720448064391,
          "locked": false
        }
      },
      {
        "order": 4,
        "info": {
          "id": "1e33e6b7-857e-4022-bdb5-92000026943c",
          "type": FenixLandingTypeEnum.SUBJECTS,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "name": "funnel2__004",
            "oneAnswer": true,
            "pictures": [
              "comfort",
              "good",
              "dissapointed",
              "neutral"
            ],
            "question": "do_you_know_crypto",
            "questions": [],
            "result": [],
            "subjects": [
              "a_lot",
              "a_moderrate_amount",
              "know_the_name",
              "never_heard"
            ],
            "withNumbering": true,
            "withoutButton": true
          },
          
          "updated_at": 1720448069696,
          "locked": false
        }
      },
      {
        "order": 5,
        "info": {
          "id": "27f30381-9526-4594-98e1-49caf10e5302",
          "type": FenixLandingTypeEnum.SUBJECTS,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "name": "funnel2__005",
            "oneAnswer": true,
            "question": "using_cryptocurrency",
            "questions": [],
            "result": [],
            "subjects": [
              "very_comfortable",
              "somewhat_comfortable",
              "neutral",
              "uncomfortable",
              "very_uncomfortable"
            ],
            "withNumbering": true,
            "withoutButton": true
          },
          
          "updated_at": 1720448075190,
          "locked": false
        }
      },
      {
        "order": 6,
        "info": {
          "id": "5ae16ac5-bae6-47c7-af9e-89cf3bc7e9a6",
          "type": FenixLandingTypeEnum.SUBJECTS,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "name": "funnel2__006",
            "oneAnswer": true,
            "pictures": [
              "comfort",
              "good",
              "dissapointed",
              "neutral"
            ],
            "question": "do_you_know_bitcoin",
            "questions": [],
            "result": [],
            "subjects": [
              "a_lot",
              "a_moderrate_amount",
              "know_the_name",
              "never_heard_it"
            ],
            "withNumbering": true,
            "withoutButton": true
          },
          
          "updated_at": 1720448081053,
          "locked": false
        }
      },
      {
        "order": 7,
        "info": {
          "id": "5685c473-745a-496e-b0ae-879f3e18f9a2",
          "type": FenixLandingTypeEnum.SUBJECTS,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "name": "funnel2__007",
            "oneAnswer": true,
            "pictures": [
              "finger-up",
              "finger-down",
              "hmm"
            ],
            "question": "do_you_know_altcoins",
            "questions": [],
            "result": [],
            "subjects": [
              "Answer_yes",
              "Answer_no",
              "not_sure"
            ],
            "withNumbering": true,
            "withoutButton": true
          },
          
          "updated_at": 1720448086811,
          "locked": false
        }
      },
      {
        "order": 8,
        "info": {
          "id": "0c7d3d1e-9498-4881-b39b-7a5b5da60d35",
          "type": FenixLandingTypeEnum.SUBJECTS,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "name": "funnel2__008",
            "oneAnswer": true,
            "pictures": [
              "finger-up",
              "finger-down",
              "hmm"
            ],
            "question": "do_you_know_blockchain",
            "questions": [],
            "result": [],
            "subjects": [
              "Answer_yes",
              "Answer_no",
              "not_sure"
            ],
            "withNumbering": true,
            "withoutButton": true
          },
          
          "updated_at": 1720448091939,
          "locked": false
        }
      },
      {
        "order": 9,
        "info": {
          "id": "e278a9b4-53ed-4bc3-b887-4a184f7f63a8",
          "type": FenixLandingTypeEnum.SUBJECTS,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "name": "funnel2__009",
            "oneAnswer": true,
            "pictures": [
              "neutral",
              "bad",
              "good",
              "comfort"
            ],
            "question": "how_often_stressed",
            "questions": [],
            "result": [],
            "subjects": [
              "all_time",
              "Most of the time",
              "Sometimes",
              "Rarely"
            ],
            "withNumbering": true,
            "withoutButton": true
          },
          
          "updated_at": 1720448097542,
          "locked": false
        }
      },
      {
        "order": 10,
        "info": {
          "id": "e7e8d8a7-3fa4-47c0-be2f-f1ff1edbcf10",
          "type": FenixLandingTypeEnum.PICTURE_FIRST,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "buttonText": "Button_Continue",
            "name": "funnel2__010",
            "picture": "Nice",
            "pictures": [],
            "questions": [],
            "result": [],
            "subjects": [],
            "text": "excellent_opportunities",
            "title": "unlock_financial_freedom",
            "withNumbering": true
          },
          
          "updated_at": 1720448102433,
          "locked": false
        }
      },
      {
        "order": 11,
        "info": {
          "id": "6ba7e714-bf6d-4b1e-8844-a00d988b51a9",
          "type": FenixLandingTypeEnum.TWO_BUTTONS,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "name": "funnel2__011",
            "pictures": [],
            "question": "own_crypto",
            "questions": [],
            "result": [],
            "subjects": [],
            "withNumbering": true
          },
          
          "updated_at": 1720448106896,
          "locked": false
        }
      },
      {
        "order": 12,
        "info": {
          "id": "74fbbbcf-a100-4fa0-88df-470969cf74d6",
          "type": FenixLandingTypeEnum.TWO_BUTTONS,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "name": "funnel2__012",
            "pictures": [],
            "question": "own_nft",
            "questions": [],
            "result": [],
            "subjects": [],
            "withNumbering": true
          },
          
          "updated_at": 1720448112285,
          "locked": false
        }
      },
      {
        "order": 13,
        "info": {
          "id": "b311fa4a-70d0-4e34-bc3c-907182a6e68c",
          "type": FenixLandingTypeEnum.TWO_BUTTONS,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "name": "funnel2__013",
            "pictures": [],
            "question": "what_is_airdrop",
            "questions": [],
            "result": [],
            "subjects": [],
            "withNumbering": true
          },
          
          "updated_at": 1720448117766,
          "locked": false
        }
      },
      {
        "order": 14,
        "info": {
          "id": "698c374d-5704-4c91-9623-1a099ed4155f",
          "type": FenixLandingTypeEnum.TWO_BUTTONS,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "name": "funnel2__014",
            "pictures": [],
            "question": "crupto_trading_experience",
            "questions": [],
            "result": [],
            "subjects": [],
            "withNumbering": true
          },
          
          "updated_at": 1720448123025,
          "locked": false
        }
      },
      {
        "order": 15,
        "info": {
          "id": "3410ae1a-a0f3-414f-9885-dab6a52dd8e5",
          "type": FenixLandingTypeEnum.SUBJECTS,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "name": "funnel2__015",
            "oneAnswer": true,
            "pictures": [
              "finger-up",
              "finger-down",
              "hmm"
            ],
            "question": "what_is_defi",
            "questions": [],
            "result": [],
            "subjects": [
              "Answer_yes",
              "Answer_no",
              "not_sure"
            ],
            "withNumbering": true,
            "withoutButton": true
          },
          
          "updated_at": 1720448129399,
          "locked": false
        }
      },
      {
        "order": 16,
        "info": {
          "id": "96f84a92-704a-4b80-9a54-b672dcfdcae7",
          "type": FenixLandingTypeEnum.PICTURE_FIRST,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "buttonText": "Button_Continue",
            "name": "funnel2__016",
            "picture": "Book",
            "pictures": [],
            "questions": [],
            "result": [],
            "subjects": [],
            "text": "passive_income_crypto",
            "title": "grow_weralth_crypto",
            "withNumbering": true
          },
          
          "updated_at": 1720448134743,
          "locked": false
        }
      },
      {
        "order": 17,
        "info": {
          "id": "f83d2c3c-1e19-44ca-bcb1-d90759a0dda7",
          "type": FenixLandingTypeEnum.SUBJECTS,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "name": "funnel2__017",
            "oneAnswer": true,
            "pictures": [
              "comfort",
              "hmm",
              "smile-blob"
            ],
            "question": "how_describe_situation",
            "questions": [],
            "result": [],
            "subjects": [
              "comfortable",
              "more_stability",
              "meet_financial_goals"
            ],
            "withNumbering": true,
            "withoutButton": true
          },
          
          "updated_at": 1720448139331,
          "locked": false
        }
      },
      {
        "order": 18,
        "info": {
          "id": "cc255fcf-c8aa-4c6e-aeeb-181f0f4c05aa",
          "type": FenixLandingTypeEnum.SUBJECTS,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "name": "funnel2__018",
            "oneAnswer": true,
            "pictures": [
              "finger-up",
              "good",
              "finger-down"
            ],
            "question": "do_have_passive_income",
            "questions": [],
            "result": [],
            "subjects": [
              "yes_do",
              "I'm trying to build",
              "No, I don't"
            ],
            "withNumbering": true,
            "withoutButton": true
          },
          
          "updated_at": 1720448144307,
          "locked": false
        }
      },
      {
        "order": 19,
        "info": {
          "id": "4b365b3f-ed76-4e76-9a65-5ebdb8eb6b3f",
          "type": FenixLandingTypeEnum.SUBJECTS,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "name": "funnel2__019",
            "oneAnswer": true,
            "pictures": [
              "finger-up",
              "finger-down",
              "hmm"
            ],
            "question": "learning_ways_to_grow",
            "questions": [],
            "result": [],
            "subjects": [
              "Answer_yes",
              "Answer_no",
              "not_sure"
            ],
            "withNumbering": true,
            "withoutButton": true
          },
          
          "updated_at": 1720448148995,
          "locked": false
        }
      },
      {
        "order": 20,
        "info": {
          "id": "ff3b5787-be97-4039-9d1d-166eae8c7769",
          "type": FenixLandingTypeEnum.PROFILE,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "buttonText": "Button_Continue",
            "hideGoBackBtn": true,
            "name": "funnel2__020",
            "noCountForStep": true,
            "pictures": [],
            "question": "now",
            "questions": [],
            "result": [],
            "stepHidden": true,
            "subjects": [],
            "text": "improve_knowledge_crypto",
            "title": "crypto_expert_profile"
          },
          
          "updated_at": 1720448154046,
          "locked": false
        }
      },
      {
        "order": 21,
        "info": {
          "id": "3da8cb12-88d8-46be-81be-a80d23a8adb4",
          "type": FenixLandingTypeEnum.SUBJECTS,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "answerKeys": [
              "pay_debts",
              "grow_wealth",
              "financial_independence",
              "passive_income",
              "other"
            ],
            "name": "funnel2__021",
            "oneAnswer": true,
            "pictures": [],
            "question": "main_goal",
            "questions": [],
            "result": [],
            "saveAnswer": true,
            "saveAs": "goal",
            "subjects": [
              "pay_debts",
              "grow_wealth",
              "financial_independence",
              "passive_income",
              "other"
            ],
            "text": "select_priority",
            "withNumbering": true,
            "withoutButton": true
          },
          
          "updated_at": 1720448159361,
          "locked": false
        }
      },
      {
        "order": 22,
        "info": {
          "id": "1e29863d-9444-44d0-936b-78f48c2a790a",
          "type": FenixLandingTypeEnum.SUBJECTS,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "answerKeys": [
              "buy_house",
              "buy_car",
              "wedding",
              "vacation",
              "retirement",
              "other"
            ],
            "name": "funnel2__022",
            "oneAnswer": true,
            "pictures": [
              "house",
              "car",
              "ring",
              "vacation",
              "calendar",
              "hmm"
            ],
            "question": "is_there_anything_special",
            "questions": [],
            "result": [],
            "saveAnswer": true,
            "saveAs": "target",
            "subjects": [
              "buy_house",
              "buy_car",
              "wedding",
              "vacation",
              "retirement",
              "other"
            ],
            "withNumbering": true,
            "withoutButton": true
          },
          
          "updated_at": 1720448163998,
          "locked": false
        }
      },
      {
        "order": 23,
        "info": {
          "id": "24e52dc2-fe7c-4229-8021-0cb932e872ed",
          "type": FenixLandingTypeEnum.SUBJECTS,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "name": "funnel2__023",
            "oneAnswer": true,
            "pictures": [],
            "question": "when_do_need_it",
            "questions": [],
            "result": [],
            "subjects": [
              "asap",
              "in_3_months",
              "in_the_coming_year",
              "i_havent_decided"
            ],
            "withNumbering": true,
            "withoutButton": true
          },
          
          "updated_at": 1720448168734,
          "locked": false
        }
      },
      {
        "order": 24,
        "info": {
          "id": "7e2c8e56-7b05-4b8f-87d8-190ca118520d",
          "type": FenixLandingTypeEnum.SUBJECTS,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "name": "funnel2__024",
            "oneAnswer": true,
            "pictures": [],
            "question": "how_much_time_ready_spend",
            "questions": [],
            "result": [],
            "subjects": [
              "15_min",
              "25_min",
              "35_min",
              "45_min"
            ],
            "withNumbering": true,
            "withoutButton": true
          },
          
          "updated_at": 1720448173173,
          "locked": false
        }
      },
      {
        "order": 25,
        "info": {
          "id": "8ca88324-a79a-4eeb-a660-fa1d9d75b401",
          "type": FenixLandingTypeEnum.STRATEGY,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "buttonText": "Button_Continue",
            "hideGoBackBtn": true,
            "name": "funnel2__025",
            "noCountForStep": true,
            "pictures": [],
            "question": "confident_in_crypto_by",
            "questions": [],
            "result": [],
            "subjects": [],
            "text": "gain_skills_by",
            "title": "personal_strategy",
            "withNumbering": true
          },
          
          "updated_at": 1720448177307,
          "locked": false
        }
      },
      {
        "order": 26,
        "info": {
          "id": "787ee23e-730f-42db-8172-584229fdc4ec",
          "type": FenixLandingTypeEnum.LOADING2,
          "scope": "fenix",
          "parameters": {
            "ageFilter": [],
            "ages": [],
            "duration": 7000,
            "hideGoBackBtn": true,
            "name": "LoadingCrypto2",
            "pictures": [],
            "questions": [],
            "result": [],
            "stepHidden": true,
            "subjects": [
              "review_4",
              "review_5",
              "review_6"
            ]
          },
          "updated_at": 1720448427259,
          "locked": false
        }
      }
    ],
  },
  "payment_screen": {
    "id": "ce1775ef-858e-47d4-8659-e6c5d804648a",
    "name": "Funnel2",
    screens: null,
    "parameters": {
      "needApple": true,
      "paymaxis": false,
      "prevScreens": [
        {
          "info": {
            "id": "d5877068-6823-45d6-a92b-403222e1c7e7",
            "locked": false,
            "parameters": {
              "buttonText": "Button_Continue",
              "name": "Email",
              "stepHidden": true,
              "title": "enter_your_email"
            },
            "scope": "fenix",
            "type": FenixEmailTypeEnum.EMAIL,
            "updated_at": 1718627545336
          },
          "order": 1
        },
        {
          "info": {
            "id": "b2f79d3b-af51-4ccc-be70-5eaec883204e",
            "locked": false,
            "parameters": {
              "ageFilter": [],
              "ages": [],
              "buttonText": "Button_Continue",
              "hideGoBackBtn": true,
              "name": "funnel2__028",
              "picture": "Graph4Weeks",
              "pictures": [],
              "question": "your_income_strategy_ready",
              "questions": [],
              "result": [],
              "stepHidden": true,
              "subjects": [],
              "text": "chart_for_illustrative",
              "title": "crypto_knowledge_level"
            },
            "scope": "fenix",
            "type": FenixLandingTypeEnum.TITLE_AND_PICTURE,
            "updated_at": 1720448183190
          },
          "order": 2
        },
        {
          "info": {
            "id": "4f0d3ffc-e68a-49c0-96f7-91589f398f93",
            "locked": false,
            "parameters": {
              "cardType": "DEFAULT",
              "checkoutDescriptionType": "SHORT",
              "crossOnTheLeft": true,
              "description": "4_weeks_crypto",
              "firstTimeOffers": [
                {
                  "buttonText": "specialOffer_startTrial",
                  "description": "product_1week",
                  "discount": "{currency}1.14",
                  "fakePercent": 55,
                  "fakePrice": 59.99,
                  "needSpecialText": false,
                  "noTextUnderLine": false,
                  "order": 1,
                  "price": "{trialPriceWithoutVAT}",
                  "productId": "e1c38b3b-5d1e-4c53-85bf-6ede0512da11",
                  "selected": false,
                  "special": false,
                  "textForSolid": "offerDisclamer_3DayTrial",
                  "textForSolidVat": "disclaimerVATTrialWeeks",
                  "totalPrice": "{TotalPrice}",
                  "underPrice": "users_stay"
                },
                {
                  "advantage": "radio_badge_mostPopular",
                  "buttonText": "get_my_plan",
                  "description": "4week_plan",
                  "discount": "{currency}0.66",
                  "fakePercent": 55,
                  "fakePrice": 59.99,
                  "needSpecialText": false,
                  "noTextUnderLine": false,
                  "order": 2,
                  "paymentFormDescription": "{OldPrice}",
                  "paymentText": "{OldPricePerDay}",
                  "price": "{trialPriceWithoutVAT}",
                  "productId": "dee04384-2bb1-4a69-9d78-73f74531cd56",
                  "selected": true,
                  "special": false,
                  "textForSolid": "offerDisclamer_withoutTrial1M",
                  "textForSolidVat": "disclaimerVATPlanWeeks",
                  "totalPrice": "{TotalPrice}",
                  "underPrice": "users_stay"
                },
                {
                  "buttonText": "get_my_plan",
                  "description": "product_12week",
                  "discount": "{currency}0.47",
                  "fakePercent": 55,
                  "fakePrice": 59.99,
                  "needSpecialText": false,
                  "noTextUnderLine": false,
                  "order": 3,
                  "paymentFormDescription": "{OldPrice}",
                  "paymentText": "{OldPricePerDay}",
                  "price": "{trialPriceWithoutVAT}",
                  "productId": "cd2592f9-d3f0-4ec4-be9b-2ff62f6763c8",
                  "selected": false,
                  "special": false,
                  "textForSolid": "Disclamer_withoutTrial_12w",
                  "textForSolidVat": "disclaimerVATPlanWeeks",
                  "totalPrice": "{TotalPrice}",
                  "underPrice": "users_stay"
                }
              ],
              "list": [
                "tools_to_generate_income_crypto",
                "practical_guide",
                "access_to_discord",
                "online_support"
              ],
              "methodsType": "THREE",
              "name": "Payment_crypto",
              "text1": "Passive income",
              "text2": "Purchasing power",
              "text3": "Passive income",
              "text4": "Purchasing power",
              "title1": "crypto_easier",
              "title2": "try_to_learn"
            },
            "scope": "fenix",
            "type": FenixPaymentTypeEnum.PAYMENT_CRYPTO,
            "updated_at": 1720602000814
          },
          "order": 3
        },
        {
          "info": {
            "id": "e404f082-0ea3-419d-b07e-8b3f7b7541b1",
            "locked": false,
            "parameters": {
              "buttonText": "activate_my_acc",
              "name": "Info",
              "picture": "Book"
            },
            "scope": "fenix",
            "type": FenixInfoTypeEnum.INFO,
            "updated_at": 1718627549992
          },
          "order": 4
        }
      ]
    },
    "locked": false
  },
  "parameters": {
    "listDisabledLocal": []
  },
}
