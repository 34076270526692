export enum AnalyticTypesEnum {
  WebOnboardingView = 'WebOnboardingView',
  WebFirstScreenVisible = 'WebFirstScreenVisible',
  WebOnboardingStageCompleted = 'WebOnboardingStageCompleted',
  WebOnboardingScroll = 'WebOnboardingScroll',
  // события в процессе авторизации и оплаты
  WebEmailEntered = 'WebEmailEntered',
  WebEmailOpened = 'WebEmailOpened',

  WebPayOfferView = 'WebPayOfferView',
  WebPayVideoOpened = 'WebPayVideoOpened',
  WebOfferTrialView = 'WebOfferTrialView',
  WebOfferTrialSelected = 'WebOfferTrialSelected',
  WebPaySwitcherClicked = 'WebPaySwitcherClicked',
  WebPaySubscribeButtonClickedLoaded = 'WebPaySubscribeButtonClickedLoaded',
  WebPaypalReady = 'WebPaypalReady',
  WebCardReady = 'WebCardReady',
  WebPaySubscribeButtonClicked = 'WebPaySubscribeButtonClicked',
  WebPayFiveMinutesClosed = 'WebPayFiveMinutesClosed',
  WebPayFirstOfferClosed = 'WebPayFirstOfferClosed',
  WebPayAdditionalOfferView = 'WebPayAdditionalOfferView',
  WebPayAdditionalOfferClicked = 'WebPayAdditionalOfferClicked',
  WebPayAdditionalOfferValidated = 'WebPayAdditionalOfferValidated',
  WebPayOfferSubscribed = 'WebPayOfferSubscribed',
  WebPayOfferValidated = 'WebPayOfferValidated',
  WebPayOfferValidationFailed = 'WebPayOfferValidationFailed',
  WebPayCrossgradeAccepted = 'WebPayCrossgradeAccepted',
  WebPayCrossgradeDeclined = 'WebPayCrossgradeDeclined',
  WebPayCrossgradeError = 'WebPayCrossgradeError',

  WebSpecoffer302View = 'WebSpecoffer302View',
  WebSpecoffer302BuyClick = 'WebSpecoffer302BuyClick',
  WebSpecoffer302Subscribed = 'WebSpecoffer302Subscribed',
  WebSpecoffer302SubscribedFailed = 'WebSpecoffer302SubscribedFailed',

  WebSpecofferView = 'WebSpecofferView',
  WebPaySpecofferClicked = 'WebPaySpecofferClicked',

  WebPayAdditionalOfferValidated_SpecialBack = 'WebPayAdditionalOfferValidated_SpecialBack',
  WebPayAdditionalOfferValidated_Special302 = 'WebPayAdditionalOfferValidated_Special302',

  WebPayAdditionalOfferValidationFailed = 'WebPayAdditionalOfferValidationFailed',
  WebPayAdditionalOfferValidationFailed_Special302 = 'WebPayAdditionalOfferValidationFailed_Special302',
  WebPayAdditionalOfferValidationFailed_SpecialBack = 'WebPayAdditionalOfferValidationFailed_SpecialBack',

  TrialSwitchedOn = 'TrialSwitchedOn',
  TrialSwitchedOff = 'TrialSwitchedOff',

  WebFirstOfferBackClick = 'WebFirstOfferBackClick',
  WebFirstOfferCloseBrowser = 'WebFirstOfferCloseBrowser',
  WebAddtionalOfferBackClick = 'WebAddtionalOfferBackClick',
  Web302ErrorAdditionalOffer = 'Web302ErrorAdditionalOffer',

  // события чекаута
  WebPayCheckoutView = 'WebPayCheckoutView',
  WebPayCheckoutReady = 'WebPayCheckoutReady',
  WebPayCheckoutClosed = 'WebPayCheckoutClosed',
  WebPayCheckoutError = 'WebPayCheckoutError',
  WebPayCheckoutClick = 'WebPayCheckoutClick',

  // события с уже активной подпиской
  WebPayTaxGuideOfferView = 'WebPayTaxGuideOfferView',
  WebPayTaxGuideAccepted = 'WebPayTaxGuideAccepted',
  WebPayTaxGuideDeclined = 'WebPayTaxGuideDeclined',
  WebPayTaxGuideOfferSubscribed = 'WebPayTaxGuideOfferSubscribed',
  WebPayTaxGuideFailed = 'WebPayTaxGuideFailed',

  WebSPAccepted = 'WebSPAccepted',
  WebSPDeclined = 'WebSPDeclined',
  WebSPOfferView= 'WebSPOfferView',
  WebSPSubscribed = 'WebSPSubscribed',
  WebSPFailed = 'WebSPFailed',

  WebPayStoreButtonVisible = 'WebPayStoreButtonVisible',
  WebPayStoreDownloadButtonClicked  = 'WebPayStoreDownloadButtonClicked ',
  WebPayReturn = 'WebPayReturn',
  WebPayCancel = 'WebPayCancel',
  WebPayRefund = 'WebPayRefund',
  WebPayCancelConfirmation = 'WebPayCancelConfirmation',
  WebPayRefundSubscriptionSelected = 'WebPayRefundSubscriptionSelected',
  WebPayRefundConfirmation = 'WebPayRefundConfirmation',



  WebPayPurchaseOfferView = 'WebPayPurchaseOfferView',
  WebPayPurchaseShowAgain = 'WebPayPurchaseShowAgain',
  WebPayPurchaseOfferSubscribeButtonClicked = 'WebPayPurchaseOfferSubscribeButtonClicked',
  WebPayPurchaseOfferSubscribed = 'WebPayPurchaseOfferSubscribed',
  WebPayPurchaseOfferSubscribedFailed = 'WebPayPurchaseOfferSubscribedFailed',

  WebInfoView = 'WebInfoView',
}
