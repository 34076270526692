import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, Observable } from 'rxjs';
import { API_URL_GATEWAY } from 'src/app/api-service.config';

import { TiktokEventsEnum } from './../enums/tiktok-events.enum';

@Injectable()
export class TiktokService {

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(API_URL_GATEWAY) private readonly api: string,
    private readonly activatedRoute: ActivatedRoute,
  ) {
  }

  public tiktokEvent(event: TiktokEventsEnum): Observable<string> {
    const body = {
      event,
      ttp: this.getFromCookie('_ttp') || '',
      ttclid: this.activatedRoute.snapshot.queryParamMap.get('ttclid') || '',
      page_url: window.location.href,
      page_referer: window.location.origin,
      user_agent: navigator.userAgent,
    };
    let url = `${ this.api }/subscriptions/analytics/event/tt`;
    if (event !== TiktokEventsEnum.ViewContent) {
      url = `${ this.api }/subscriptions/analytics/event/tt/auth`;
    }
    return this.httpClient
      .post<any>(url, body)
      .pipe(map(() => {
        return 'OK';
      }));
  }

  private getFromCookie(name: string): string | null {
    const matches = document.cookie.match(new RegExp(
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
    ));
    return matches ? decodeURIComponent(matches[1]) : null;
  }
}
